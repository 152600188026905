$media: screen;
$feature_min: min-width;
$feature_max: max-width;
$value_one: 576px;
$value_two: 767px;
$value_three: 768px;
$value_four: 991px;
$value_five: 992px;
$value_six: 1199px;
$value_seven: 1200px;
$value_eight: 1300px;
$value_nine: 1550px;
$value_ten: 1800px;

/* Max width 767px */
@media only #{$media} and ($feature_max : $value_two) {
    .contact-phone {
        margin-bottom: 1rem;
    }
    .subscribe-content {
        margin-bottom: 2rem;
    }
    .single-pricing-table .pricing-features {
        font-size: 13px;
    }
    .pricing-list-tab h1 {
        font-size: 24px;
    }
    .is-case-studies-list-tabs-helpdesk .nav .nav-item .nav-link {
        font-size: 13px;
    }
    .features-card-section h1 {
        font-size: 24px;
    }
    .is-expertise-content span {
        text-align: center;
    }
    .saas-banner {
        margin-top: 0;
    }

    .banner-btn {
        display: flex;
        justify-content: center;
    }

    .single-pricing-table .pricing-features li span {
        display: none;
    }

    .sm-services-card-image {
        display: none;
    }
    .is-case-studies-area .section-title-with-large-box {
        display: block !important;
        text-align: center;
    }

    .me-choose-content {
        text-align: left;
        font-size: 13px;
    }
    .contact-us-right-side {
        margin-top: 2rem;
    }
    .vela-chat-img {
        margin-left: 0;
    }
    .explore-product-btn {
        margin-left: 0;
        display: block;
    }
    .schedule-demo-btn {
        display: block;
    }
    #myVideo {
        width: 100%;
        height: auto;
    }
    body {
        font-size: 13px;
    }
    p {
        font-size: 13px;
    }
    .ptb-100 {
        padding: {
            top: 60px;
            bottom: 60px;
        }
    }
    .pt-100 {
        padding-top: 60px;
    }
    .pb-100 {
        padding-bottom: 60px;
    }
    .pt-70 {
        padding-top: 30px;
    }
    .pb-70 {
        padding-bottom: 30px;
    }
    .section-title {
        max-width: 100%;
        margin: {
            left: 0;
            right: 0;
            bottom: 40px;
        }
        h2 {
            font-size: 24px;
            line-height: 1.3;
        }
    }
    .default-btn {
        font-size: 13px;
        padding: {
            left: 50px;
            right: 25px;
            top: 12px;
            bottom: 12px;
        }
        i {
            font-size: 18px;
        }
    }
    .optional-btn {
        font-size: 13px;
        padding: {
            left: 50px;
            right: 25px;
            top: 12px;
            bottom: 12px;
        }
        i {
            font-size: 18px;
        }
    }

    .main-banner {
        padding-top: 60px;
    }
    .main-banner-content {
        height: auto;

        .content {
            max-width: 100%;
            padding-right: 0;
            text-align: center;
            margin: {
                left: 0;
                top: 0;
            }
            padding: 0.5rem;
            h1 {
                font-size: 30px;
            }
            p {
                margin-top: 15px;
                max-width: 100%;
                padding: 0.5rem;
            }
            .default-btn {
                margin-top: 10px;
            }
        }
    }
    .banner-content {
        padding: {
            top: 60px;
            bottom: 0;
        }
        .content {
            max-width: 100%;
            padding-right: 0;
            text-align: center;
            margin: {
                left: 0;
            }
            h1 {
                font-size: 30px;
            }
            p {
                margin-left: 1rem;
                margin-right: 1rem;
                margin-top: 15px;
                max-width: 100%;
            }
            .default-btn {
                margin-top: 10px;
            }
        }
    }
    .banner-image {
        margin-top: 60px;
        height: auto;
        border-radius: 0;
        background-image: unset !important;
        padding-top: 50px;

        img {
            display: inline-block;
        }
    }
    .banner-img {
        margin-top: 60px;
        height: auto;
        border-radius: 0;
        background-image: unset !important;
        padding-top: 50px;

        img {
            display: inline-block;
        }
    }
    .banner-content-slides {
        max-width: fit-content;
        &.owl-theme {
            .owl-nav {
                text-align: center;
                margin-top: 35px;

                [class*="owl-"] {
                    font-size: 20px;
                    margin: 0 4px;
                    width: 35px;
                    height: 35px;
                    line-height: 40px;
                }
            }
        }
    }
    .chat-wrapper {
        position: relative;
        left: 0;
        top: 0;
        transform: unset;
        width: auto;
        height: auto;
        margin-bottom: -113%;
        padding: {
            left: 15px;
            right: 15px;
        }
        .chat {
            ul {
                margin-bottom: 5rem;
            }
        }
        .chat-container {
            height: 365px;
        }
    }
    .message-right {
        .message-text {
            max-width: 100%;
        }
    }
    .message-left {
        .message-text {
            max-width: 100%;
        }
    }

    .partner-area {
        .container {
            max-width: 100%;
        }
    }
    .partner-title {
        text-align: center;
        margin-bottom: 30px;

        h3 {
            font-size: 18px;
        }
    }

    .services-area {
        &.bg-right-color {
            &::before {
                display: none;
            }
        }
        &.bg-left-color {
            &::before {
                display: none;
            }
        }
    }
    .services-content {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        order: 1;

        .content {
            max-width: 100%;
            text-align: center;

            &.left-content {
                margin-left: 0;
            }
            h2 {
                font-size: 24px;
                line-height: 1.3;
            }
            p {
                max-width: 100%;
                margin-top: 15px;
            }
            .default-btn {
                margin-top: 10px;
            }
        }
    }
    .services-image {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        order: 2;

        .image {
            margin-top: 30px;
        }
    }

    .video-box {
        max-width: 100%;
        margin: {
            left: auto;
            right: auto;
        }
        .video-btn {
            width: 55px;
            height: 55px;
            border-radius: 50%;
            font-size: 50px;

            &::after,
            &::before {
                border-radius: 50%;
            }
        }
    }
    .shape1,
    .shape2,
    .shape3,
    .shape4,
    .shape5,
    .shape6,
    .shape7,
    .shape8,
    .shape9,
    .shape10,
    .shape11,
    .shape12,
    .shape13,
    .shape20,
    .shape21,
    .shape22,
    .shape23,
    .shape24,
    .shape26,
    .shape14,
    .shape15,
    .shape16,
    .shape17,
    .shape18 {
        display: none;
    }

    .about-content {
        text-align: center;

        h2 {
            font-size: 24px;
            line-height: 1.3;
        }
    }
    .about-image {
        margin-top: 30px;
    }

    .funfacts-inner {
        max-width: 100%;
        margin-top: 40px;
        margin: {
            left: 0;
            right: 0;
        }
    }
    .single-funfacts {
        h3 {
            margin-bottom: 2px;
            font-size: 24px;

            .sign-icon {
                font-size: 22px;
            }
        }
    }
    .contact-cta-box {
        max-width: 100%;
        padding: 25px;
        text-align: center;
        margin: {
            left: 0;
            top: 10px;
            right: 0;
        }
        h3 {
            font-size: 18px;
        }
        p {
            margin-top: 10px;
        }
        .default-btn {
            position: relative;
            right: 0;
            top: 0;
            transform: unset;
            margin-top: 15px;
        }
    }

    .single-features-box {
        margin-top: 32px;

        h3 {
            font-size: 18px;
        }
        .icon {
            width: 65px;
            height: 65px;
            font-size: 35px;
            bottom: 32px;

            &::before {
                right: -10px;
                bottom: -10px;
                border-width: 3px;
            }
        }
    }
    .features-box {
        text-align: center;
        padding: 20px;

        .icon {
            width: 70px;
            height: 70px;
            font-size: 35px;
            margin: {
                left: auto;
                right: auto;
            }
        }
        h3 {
            font-size: 18px;
        }
    }

    .single-feedback-item {
        margin-top: 0;

        img {
            display: none !important;
        }
        .feedback-desc {
            margin-left: 0;
            padding: 25px;

            .rating {
                i {
                    font-size: 14px;
                }
            }
            .client-info {
                h3 {
                    font-size: 16px;
                }
                span {
                    font-size: 13px;
                    margin-top: 5px;
                }
            }
        }
    }
    .feedback-slides {
        &.owl-theme {
            .owl-stage-outer {
                padding: {
                    top: 30px;
                    left: 15px;
                    right: 15px;
                }
                margin: {
                    left: -15px;
                    right: -15px;
                    top: -30px;
                }
            }
            .owl-nav {
                &.disabled + .owl-dots {
                    margin-top: 0;
                }
            }
        }
    }
    .single-testimonials-item {
        padding: 20px;

        .client-info {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 15px;

            h3 {
                font-size: 16px;
            }
            span {
                font-size: 13px;
                margin-top: 5px;
            }
        }
        .testimonials-desc {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            text-align: center;
            padding-left: 0;

            .rating {
                i {
                    font-size: 14px;
                }
            }
        }
    }

    .single-team-box {
        .content {
            h3 {
                font-size: 18px;
            }
            span {
                font-size: 13px;
            }
        }
    }

    .pricing-list-tab {
        .tabs {
            li {
                a {
                    font-size: 14px;

                    i {
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .single-pricing-table {
        padding-bottom: 25px;

        .pricing-header {
            padding: {
                top: 20px;
                bottom: 15px;
            }
            h3 {
                font-size: 18px;
            }
        }
        .price {
            font-size: 30px;

            sup {
                top: -15px;
                font-size: 14px;
            }
            sub {
                bottom: 2px;
                font-size: 13px;
            }
        }
        .pricing-features {
            margin: {
                left: 30px;
                right: 30px;
            }
        }
    }

    .faq-accordion {
        margin-left: 0;
        max-width: 100%;
        text-align: center;

        h2 {
            margin-bottom: 30px;
            font-size: 24px;
            line-height: 1.3;
        }
        .accordion {
            text-align: start;

            .accordion-title {
                padding: 10px 40px 10px 15px;
                font-size: 13px;

                i {
                    right: 15px;
                }
            }
            .accordion-content {
                font-size: 13px;
            }
        }
    }
    .faq-image {
        margin-top: 35px;
    }

    .single-clients-logo {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;

        &:nth-child(6) {
            margin-left: 0;
        }
        a {
            padding: 15px;
        }
    }

    .free-trial-content {
        h2 {
            font-size: 24px;
            line-height: 1.3;
        }
        p {
            margin-top: 15px;
        }
        .default-btn {
            margin-top: 5px;
        }
    }

    .single-blog-post {
        .post-image {
            .date {
                padding: 8px 20px 0;
                font-size: 14px;
            }
        }
        .post-content {
            padding: 20px;

            h3 {
                font-size: 18px;
            }
            .post-info {
                .post-by {
                    -ms-flex: 0 0 55%;
                    flex: 0 0 55%;
                    max-width: 55%;

                    h6 {
                        font-size: 14px;
                    }
                }
                .details-btn {
                    -ms-flex: 0 0 45%;
                    flex: 0 0 45%;
                    max-width: 45%;

                    a {
                        width: 35px;
                        height: 35px;
                        font-size: 20px;
                    }
                }
            }
        }
    }
    .blog-notes {
        margin-top: 0;

        p {
            line-height: 1.8;
        }
    }

    .blog-details-desc {
        .article-content {
            h3 {
                margin-bottom: 13px;
                font-size: 18px;
                line-height: 1.3;
            }
        }
        .article-footer {
            margin-top: 25px;

            .article-tags a {
                font-size: 13px;
                margin-top: 5px;
            }
        }
    }
    blockquote,
    .blockquote {
        padding: 20px !important;

        p {
            font-size: 15px !important;
        }
    }
    .prev-link-wrapper {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 0;

        .prev-title {
            font-size: 14px;
        }
        .meta-wrapper {
            font-size: 13px;
        }
    }
    .next-link-wrapper {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0;
        margin-top: 30px;

        .next-title {
            font-size: 14px;
        }
        .meta-wrapper {
            font-size: 13px;
        }
    }
    .comments-area {
        .comment-body {
            padding-left: 0;

            .reply {
                a {
                    font-size: 12px;
                }
            }
        }
        .comments-title {
            margin-bottom: 25px;
            font-size: 18px;
        }
        .comment-author {
            font-size: 15px;

            .avatar {
                left: 0;
                position: relative;
                display: block;
                margin-bottom: 10px;
            }
        }
        .comment-respond {
            .comment-reply-title {
                font-size: 18px;
            }
            .comment-form-author {
                width: 100%;
                padding-right: 0;
            }
            .comment-form-email {
                width: 100%;
                padding-left: 0;
            }
            .form-submit {
                input {
                    padding: 10px 25px;
                    font-size: 14px;
                }
            }
        }
    }

    .app-download-content {
        text-align: center;
        margin-top: 30px;

        .sub-title {
            padding: 4px 20px;
            margin-bottom: 12px;
            font-size: 13px;
        }
        h2 {
            font-size: 24px;
            line-height: 1.3;
        }
        .btn-box {
            margin-top: 0;
            margin-bottom: 0.5rem;

            .apple-store-btn {
                text-align: start;
                margin-right: 0;
                display: block;
                width: 100%;
                padding: 12px 25px 12px 74px;
                font-size: 13px;

                span {
                    font-size: 18px;
                }
            }
            .play-store-btn {
                text-align: start;
                display: block;
                width: 100%;
                padding: 12px 25px 12px 74px;
                font-size: 13px;
                margin-top: 10px;

                span {
                    font-size: 18px;
                }
            }
        }
    }

    .subscribe-content {
        padding: 35px 25px;

        h2 {
            max-width: 100%;
            font-size: 25px;
            line-height: 1.3;
            margin: {
                left: 0;
                right: 0;
            }
        }
        form {
            max-width: 100%;
            margin: {
                top: 30px;
                left: 0;
                right: 0;
            }
            button {
                font-size: 13px;
                margin-top: 15px;
            }
        }
    }

    .page-title-area {
        margin-top: 0;
        padding: {
            top: 60px;
            bottom: 50px;
        }
    }
    .page-title-content {
        h2 {
            font-size: 30px;
        }
        p {
            margin-top: 10px;
        }
    }

    .login-image {
        height: auto;
        background-image: unset !important;

        img {
            display: inline-block;
        }
    }
    .login-content {
        height: auto;
        padding: 60px 15px;

        .login-form {
            max-width: 100%;
            margin: {
                left: 0;
                right: 0;
            }
            .logo {
                margin-bottom: 25px;
            }
            h3 {
                font-size: 24px;
            }
            form {
                .form-control {
                    height: 50px;
                    font-size: 13px;
                }
            }
        }
    }

    .signup-image {
        height: auto;
        background-image: unset !important;

        img {
            display: inline-block;
        }
    }
    .signup-content {
        height: auto;
        padding: 60px 15px;

        .signup-form {
            max-width: 100%;
            margin: {
                left: 0;
                right: 0;
            }
            .logo {
                margin-bottom: 25px;
            }
            h3 {
                font-size: 24px;
            }
            form {
                .form-control {
                    height: 50px;
                    font-size: 13px;
                }
            }
        }
    }

    .error-area {
        height: 100%;
        padding: {
            top: 120px;
            bottom: 120px;
        }
    }
    .error-content {
        max-width: 100%;
        margin: {
            left: 0;
            right: 0;
        }
        h3 {
            font-size: 25px;
            margin: {
                top: 30px;
                bottom: 10px;
            }
        }
    }

    .pagination-area {
        margin-top: 25px;

        .page-numbers {
            width: 40px;
            height: 40px;
            margin: 0 2px;
            line-height: 41px;
            font-size: 18px;
        }
    }

    .widget-area {
        margin-top: 40px;

        .widget {
            .widget-title {
                font-size: 18px;
            }
        }
        .widget_spacle_posts_thumb {
            .item {
                .info {
                    time {
                        margin-top: 0;
                    }
                }
                .title {
                    font-size: 14px;
                }
            }
        }
        .widget_categories {
            ul {
                li {
                    font-size: 14px;
                }
            }
        }
        .widget_archive {
            ul {
                li {
                    font-size: 14px;
                }
            }
        }
        .widget_meta {
            ul {
                li {
                    font-size: 14px;
                }
            }
        }
        .tagcloud {
            a {
                font-size: 13px !important;
            }
        }
    }

    .contact-inner {
        padding: 25px 20px;
    }
    .contact-features-list {
        padding-right: 0;
        border-right: none;
        text-align: center;

        h3 {
            font-size: 18px;
            line-height: 1.3;
        }
        ul {
            text-align: start;
        }
    }
    .contact-form {
        text-align: center;
        margin-top: 30px;

        h3 {
            margin-bottom: 20px;
            font-size: 20px;
        }
        form {
            .form-group {
                margin-bottom: 13px;
            }
            .form-control {
                height: 45px;
                font-size: 13px;
                padding: 0 10px;
            }
        }
    }
    .contact-info {
        margin-top: 40px;

        .contact-info-content {
            h3 {
                font-size: 18px;
                line-height: 1.3;
            }
            h2 {
                font-size: 24px;

                span {
                    margin-bottom: 3px;
                    font-size: 13px;
                }
            }
            .social {
                margin-top: 20px;

                li {
                    a {
                        width: 35px;
                        height: 35px;
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .footer-area {
        padding-top: 60px;
    }
    .single-footer-widget {
        h3 {
            margin-bottom: 20px;
            font-size: 18px;
        }
        .social {
            li {
                a {
                    width: 32px;
                    height: 32px;
                    line-height: 36px;
                    font-size: 20px;
                }
            }
        }
    }
    .divider {
        display: none;
    }
    .copyright-area {
        margin-top: 30px;
    }

    .go-top {
        right: 10px;
        width: 40px;
        height: 40px;
        font-size: 36px;

        &.active {
            bottom: 10px;
        }
    }

    .main-banner-one {
        .banner-image {
            padding-top: 30px;
            padding-bottom: 30px;
            padding-left: 20px;
            padding-right: 20px;
            .mbanner-img {
                display: none;
            }
        }
    }
    .single-features-card {
        h3 {
            font-size: 20px;
        }
    }
    .feature-box {
        padding: 12px 18px;
    }
    .services-content {
        .content {
            padding-left: 0;
        }
    }
    .funfact-style-two {
        i {
            font-size: 25px;
            width: 60px;
            height: 60px;
            line-height: 60px;
        }
    }
    .features-box-one {
        h3 {
            font-size: 20px;
        }
    }
    .banner-image.mbanner-bg-one {
        .animate-banner-image {
            margin-top: 0;
        }
    }
    .main-banner-two {
        .banner-image-slider {
            .banner-image {
                padding-top: 0;
                height: 400px;
                img {
                    display: block;
                }
            }
        }
    }

    .banner-img {
        &.banner-video {
            height: 400px;
            padding-top: 0;
        }
    }
    .single-pricing-table.left-align {
        .pricing-header,
        .price {
            padding-left: 25px;
            padding-right: 25px;
        }
        .pricing-features {
            margin-left: 25px;
            margin-right: 25px;
        }
    }

    /* SaaS home page */
    .saas-banner {
        padding-bottom: 60px;
        padding-top: 60px;
        text-align: center;
        .saas-image {
            &.mt-70 {
                margin-top: 0;
            }
            img {
                display: none;
                &:last-child {
                    display: block;
                    position: relative;
                    margin: auto;
                }
            }
        }
        .hero-content {
            text-align: left;
            h1 {
                text-align: center;
                font-size: 30px;
                line-height: 1.3;
            }
            p {
                font-size: 16px;
                margin-top: 15px;
                margin-bottom: 25px;
            }
            .banner-btn {
                max-width: 330px;
                margin: auto;
            }
            .video-btn {
                margin-left: 10px;
                font-size: 14px;
                i {
                    font-size: 17px;
                    height: 30px;
                    width: 30px;
                    line-height: 30px;
                    padding-left: 4px;
                    margin-right: 5px;
                }
            }
        }
        .hero-content.pl-4 {
            padding: 0 !important;
            margin-top: 30px;
        }
    }
    .shape-rotate {
        display: none;
    }

    .features-inner-content {
        .features-item {
            padding-left: 70px;
            h3 {
                font-size: 20px;
            }
            i {
                width: 55px;
                height: 55px;
                line-height: 55px;
                font-size: 25px;
                border-radius: 100% !important;
            }
        }
    }
    .overview-left-img {
        margin-bottom: 30px;
    }
    .overview-right-img {
        margin-top: 30px;
    }
    .overview-item {
        margin-bottom: 30px;
        padding-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
    /* End SaaS home page */

    /* Service details */
    .service-details-content {
        h1,
        h2,
        h3,
        h4 {
            font-size: 20px;
        }
        .service-details-info {
            .single-info-box {
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    /* End Service details */

    .section-title {
        &.text-start {
            text-align: center !important;
            max-width: 100%;
        }
    }

    .digital-agency-banner {
        margin-top: 0;
        position: relative;
        z-index: 1;
        padding: {
            top: 100px;
            bottom: 100px;
        }
        &::before {
            content: "";
            background-color: #000000;
            z-index: -1;
            position: absolute;
            opacity: 0.4;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
        .container {
            max-width: 100%;
        }
    }
    .digital-agency-banner-content {
        text-align: center;
        max-width: 100%;

        h1 {
            font-size: 30px;
            margin-bottom: 20px;
        }
        .default-btn {
            margin-top: 10px;
        }
    }

    .single-featured-box {
        h3 {
            font-size: 18px;
        }
    }

    .about-area {
        .section-title {
            &.text-start {
                margin-bottom: 40px;
            }
        }
        .container-fluid {
            padding: {
                left: 15px;
                right: 15px;
            }
        }
    }
    .about-inner-area {
        padding: {
            top: 0;
            bottom: 0;
        }
    }
    .about-inner-image {
        height: auto;
        background-image: unset !important;

        img {
            display: inline-block;
        }
    }
    .about-inner-content {
        text-align: left;
        padding: 30px 25px;
        margin: {
            top: 0;
            bottom: 0;
        }
        .content {
            max-width: 100%;
            text-align: left;

            h2 {
                text-align: center;
                font-size: 20px;
            }
            .features-list {
                margin-top: 20px;

                li {
                    font-size: 15px;
                    font-weight: normal;

                    i {
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        font-size: 23px;
                        top: 0;
                        position: relative;
                        margin: {
                            left: auto;
                            right: 0.5rem;
                            bottom: 12px;
                        }
                    }
                }
            }
        }
    }

    .single-services-box {
        .content {
            padding: 25px 20px;

            h3 {
                font-size: 18px;
                margin: {
                    right: 0;
                    bottom: 9px;
                }
            }
        }
        .row {
            .col-lg-6 {
                &:nth-child(1) {
                    order: 2;
                }
                &:nth-child(2) {
                    order: 1;
                }
            }
        }
        .image {
            height: auto;
            -webkit-clip-path: unset !important;
            clip-path: unset !important;
            background-image: unset !important;

            img {
                display: inline-block;
            }
        }
    }

    .case-studies-area {
        .container-fluid {
            padding: {
                left: 15px;
                right: 15px;
            }
        }
    }
    .case-studies-slides {
        &.owl-theme {
            left: 0;

            .owl-nav {
                position: relative;
                right: 0;
                top: 0;
                margin-bottom: 30px;

                [class*="owl-"] {
                    font-size: 25px;
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    margin: {
                        left: 4px !important;
                        right: 4px !important;
                    }
                }
            }
        }
    }
    .single-case-studies-item {
        .content {
            padding: 30px 20px 20px;

            h3 {
                font-size: 18px;
            }
        }
    }

    .feedback-area {
        .section-title {
            &.text-start {
                margin-bottom: 40px;
            }
        }
    }
    .single-testimonials-box {
        text-align: center;

        p {
            font-size: 14px;
        }
        .client-info {
            margin-top: 20px;
            text-align: start;

            .d-flex {
                justify-content: center;
            }
            .title {
                h3 {
                    font-size: 16px;
                }
            }
        }
    }
    .testimonials-slides-two {
        &.owl-theme {
            .owl-nav.disabled + .owl-dots {
                text-align: center;
            }
        }
    }

    .team-area-two {
        .section-title {
            margin-bottom: 40px;
        }
    }
    .single-team-member {
        .content {
            padding: 30px 15px 15px;

            h3 {
                font-size: 18px;
            }
        }
        .image {
            .social-link {
                li {
                    a {
                        transform: scale(1) !important;
                    }
                }
            }
        }
    }
    .col-lg-3 {
        &:nth-child(1) {
            .single-team-member {
                margin-top: 0;
            }
        }
        &:nth-child(2) {
            .single-team-member {
                margin-top: 0;
            }
        }
        &:nth-child(3) {
            .single-team-member {
                margin-top: 0;
            }
        }
    }

    .single-blog-post-item {
        .post-content {
            .category {
                margin-bottom: 8px;
                font-size: 13px;
            }
            h3 {
                font-size: 18px;
            }
        }
    }
    .blog-slides {
        &.owl-theme {
            .owl-nav {
                position: relative;
                right: 0;
                top: 0;
                margin-bottom: 30px;

                [class*="owl-"] {
                    font-size: 25px;
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    margin: {
                        left: 4px !important;
                        right: 4px !important;
                    }
                }
            }
        }
    }

    .company-preview-area {
        padding: {
            top: 100px;
            bottom: 100px;
        }
    }
    .company-preview-video {
        .video-btn {
            width: 70px;
            height: 70px;
            font-size: 60px;
        }
    }

    .lets-talk-content {
        text-align: center;

        h2 {
            margin-bottom: 12px;
            font-size: 24px;
        }
    }
    .lets-talk-btn {
        text-align: center;
        margin-top: 18px;
    }

    .section-title-with-large-box {
        span {
            font-size: 14px;
        }
        h2 {
            font-size: 25px;
        }
    }

    .seo-marketing-banner {
        padding: {
            top: 50px;
            bottom: 50px;
        }
        .container-fluid {
            padding-right: calc(var(--bs-gutter-x) * 0.5);
            padding-left: calc(var(--bs-gutter-x) * 0.5);
            max-width: 540px;
        }
    }
    .seo-marketing-banner-content {
        h1 {
            font-size: 32px;
        }
        .banner-btn {
            li {
                margin-right: 10px;

                .video-btn {
                    font-size: 12.5px;

                    i {
                        font-size: 14px;
                        height: 30px;
                        width: 30px;
                        line-height: 30px;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
    .seo-marketing-banner-shape-1,
    .seo-marketing-banner-shape-2,
    .seo-marketing-banner-shape-3 {
        display: none;
    }
    .seo-marketing-banner-image {
        text-align: center;
        margin-top: 35px;
    }

    .sm-features-card {
        h3 {
            font-size: 18px;
        }
    }

    .sm-about-content {
        padding-left: 0;
        margin-top: 35px;

        h3 {
            font-size: 24px;
        }
        .list {
            li {
                font-size: 15px;
            }
        }
    }
    .sm-about-shape-1,
    .sm-about-shape-2 {
        display: none;
    }

    .sm-websites-inner-area {
        padding: {
            left: 20px;
            right: 20px;
        }
    }
    .sm-websites-content {
        padding-right: 0;

        h3 {
            font-size: 24px;
            margin-bottom: 14px;
        }
    }
    .sm-websites-image {
        text-align: center;
        margin-top: 30px;
    }

    .sm-services-card {
        padding: 20px;

        .content {
            padding-left: 0;

            .icon {
                position: relative;
                margin-bottom: 20px;

                i {
                    height: 70px;
                    width: 70px;
                    line-height: 70px;
                    font-size: 30px;
                }
            }
            h3 {
                font-size: 18px;
                margin-bottom: 12px;
            }
        }
        &.some-left {
            left: 0;
        }
        &.some-right {
            right: 0;
        }
    }
    .sm-services-card-image {
        padding: {
            left: 0;
            right: 0;
        }
        text-align: center;
    }
    .sm-services-shape {
        display: none;
    }

    .sm-funfacts-inner-box {
        padding: {
            left: 20px;
            right: 20px;
        }
    }

    .sm-single-funfacts {
        padding-left: 0;
        text-align: center;

        img {
            position: relative;
            top: unset;
            transform: unset;
            margin-bottom: 20px;
        }
        h3 {
            font-size: 35px;

            .sign-icon {
                font-size: 30px;
            }
        }
    }

    .sm-work-content {
        h3 {
            font-size: 24px;
        }
        .sm-work-inner-card {
            h4 {
                font-size: 18px;
                margin-bottom: 12px;
            }
        }
    }
    .sm-work-image {
        text-align: center;
        margin-top: 35px;
    }

    .sm-faq-image {
        text-align: center;
    }
    .sm-faq-accordion {
        padding-left: 0;
        margin-top: 35px;

        h2 {
            margin-bottom: 25px;
            font-size: 24px;
        }
    }

    .sm-partner-inner-box {
        padding: {
            left: 20px;
            right: 20px;
        }
        .section-title-with-large-box {
            span {
                font-size: 20px;
            }
        }
    }
    .sm-partner-shape {
        display: none;
    }

    .sm-overview-card {
        .image-icon {
            height: 100px;
            width: 100px;
            line-height: 100px;

            img {
                max-width: 50px;
            }
        }
        h3 {
            font-size: 18px;
        }
    }

    .sm-pricing-card {
        padding: 20px;

        .pricing-header {
            margin-bottom: 20px;

            h3 {
                text-align: center;
                font-size: 18px;
            }
        }
        .price {
            font-size: 35px;

            span {
                font-size: 12px;
                top: -5px;
            }
        }
        .pricing-features {
            margin-top: 22px;

            li {
                color: #4a6f8a !important;
                font-size: 13px;
                padding-left: 22px;
            }
        }
    }

    .sm-subscribe-inner-box {
        padding: {
            left: 20px;
            right: 20px;
        }
    }
    .sm-subscribe-shape-1,
    .sm-subscribe-shape-2 {
        display: none;
    }

    .sm-blog-card {
        .blog-content {
            h3 {
                font-size: 20px;
            }
        }
    }
    .sm-blog-shape {
        display: none;
    }

    .footer-area-with-black-color {
        .single-footer-widget {
            &.ps-5 {
                padding-left: 0 !important;
            }
        }
    }
    .footer-shape-1,
    .footer-shape-2 {
        display: none;
    }

    .top-bar-area {
        .container-fluid {
            padding-right: calc(var(--bs-gutter-x) * 0.5);
            padding-left: calc(var(--bs-gutter-x) * 0.5);
            max-width: 540px;
        }
    }
    .top-bar-info {
        text-align: center;

        li {
            margin-bottom: 10px;
        }
    }
    .top-bar-social {
        justify-content: center;

        li {
            a {
                i {
                    font-size: 15px;
                }
            }
        }
    }

    .is-banner-item {
        padding: {
            top: 65px;
            bottom: 150px;
        }
        margin: {
            left: 0;
            right: 0;
        }
        border-radius: 0;
    }
    .is-banner-content {
        h1 {
            font-size: 32px;
        }
    }
    .is-banner-slides {
        &.owl-theme {
            .owl-nav {
                right: 0;
                left: 0;
                top: unset;
                transform: unset;
                bottom: 55px;

                [class*="owl-"] {
                    margin: 0 5px;
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    font-size: 18px;
                    display: inline-block;
                }
            }
        }
    }

    .is-features-card {
        padding: 80px 25px 25px 25px;

        h3 {
            font-size: 18px;
        }
        .icon {
            i {
                height: 65px;
                width: 65px;
                line-height: 65px;
                font-size: 30px;
            }
        }
    }

    .is-about-content {
        padding-right: 0;
        text-align: center;

        h3 {
            font-size: 24px;
        }
        p {
            text-align: left;
        }
    }
    .is-about-image {
        text-align: center;
        margin-top: 35px;

        .is-about-shape {
            display: none;
        }
    }

    .is-services-area {
        .container-fluid {
            padding-right: calc(var(--bs-gutter-x) * 0.5);
            padding-left: calc(var(--bs-gutter-x) * 0.5);
            max-width: 540px;
        }
    }
    .is-services-card {
        padding: 20px;

        h3 {
            font-size: 18px;
        }
    }

    .is-expertise-content {
        padding-left: 0;
        margin-top: 30px;

        h3 {
            font-size: 20px;
        }
        .expertise-funfacts {
            h2 {
                font-size: 28px;

                .sign-icon {
                    font-size: 25px;
                    left: -2px;
                    top: -1px;
                }
            }
            p {
                font-size: 14.5px;
            }
        }
    }

    .is-case-studies-area {
        .section-title-with-large-box {
            .default-btn {
                display: none;
            }
        }
    }
    .is-case-studies-list-tabs {
        .nav {
            .nav-item {
                .nav-link {
                    padding: 15px 15px 15px 60px;
                    font-size: 14px;

                    .number {
                        height: 35px;
                        width: 35px;
                        line-height: 35px;
                        font-size: 20px;
                    }
                }
            }
        }
    }
    .is-case-studies-image {
        padding-left: 0;
        margin-top: 30px;

        .content {
            padding: 20px;
            border-radius: 10px;
            position: relative;
            right: 0;
            bottom: 0;
            max-width: 100%;
            margin-top: 10px;
        }
    }

    .is-testimonials-card {
        p {
            padding: 25px;
        }
    }

    .is-team-content {
        padding-right: 0;
        margin-bottom: 35px;

        h3 {
            font-size: 24px;
        }
    }
    .is-team-member {
        .content {
            h3 {
                font-size: 18px;
            }
        }
    }
    .is-inner-team-box {
        .col-lg-6 {
            &:nth-child(1) {
                margin-bottom: 30px;
            }
            &:nth-child(2) {
                margin-bottom: 30px;
            }
            &:nth-child(3) {
                margin-bottom: 30px;
            }
        }
    }

    .is-analysis-content {
        padding-left: 0;
        margin-top: 35px;

        h3 {
            text-align: center;
            font-size: 24px;
        }
    }

    .me-banner-area {
        .container-fluid {
            padding-right: calc(var(--bs-gutter-x) * 0.5);
            padding-left: calc(var(--bs-gutter-x) * 0.5);
            max-width: 540px;
        }
    }
    .me-banner-content {
        h1 {
            font-size: 32px;
        }
        .banner-btn {
            li {
                margin-right: 10px;

                .video-btn {
                    font-size: 12.5px;

                    i {
                        font-size: 15px;
                        height: 30px;
                        width: 30px;
                        line-height: 30px;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
    .me-banner-image {
        margin-top: 30px;
        text-align: center;
    }
    .me-banner-shape {
        display: none;
    }

    .me-partner {
        .section-title-with-large-box {
            span {
                font-size: 18px;
            }
        }
    }

    .me-features-card {
        h3 {
            font-size: 18px;
        }
    }

    .me-about-content {
        padding-left: 0;
        margin-top: 30px;

        h3 {
            font-size: 24px;
        }
        .list {
            li {
                font-size: 15px;
            }
        }
    }

    .sm-funfacts-inner-box {
        &.bg-with-linear-gradient {
            .sm-single-funfacts {
                i {
                    height: 60px;
                    width: 60px;
                    line-height: 60px;
                    font-size: 30px;
                    position: relative;
                    margin-bottom: 15px;
                }
                p {
                    font-size: 14.5px;
                }
            }
        }
    }

    .me-services-shape1,
    .me-services-shape2,
    .me-services-shape3 {
        display: none;
    }

    .me-choose-content {
        h3 {
            text-align: center;
            font-size: 24px;
        }
        p {
            font-size: 13px;
            font-weight: normal;
        }
        .choose-inner-card {
            h4 {
                font-size: 18px;
            }
            p {
                font-size: 13px;
                font-weight: normal;
            }
        }
    }
    .me-choose-image {
        text-align: center;
        margin-top: 35px;
        margin-right: 0;
    }

    .me-team {
        .row {
            .col-lg-3 {
                .is-team-member {
                    margin-bottom: 30px;
                }
                &:last-child {
                    .is-team-member {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .me-team-shape {
        display: none;
    }

    .sm-pricing-section-content {
        text-align: center;
        margin-bottom: 30px;

        h3 {
            font-size: 24px;
        }
        p {
            text-align: left;
        }
    }
    .me-pricing-shape1,
    .me-pricing-shape2 {
        display: none;
    }

    .me-testimonials-shape1,
    .me-testimonials-shape2 {
        display: none;
    }
}

/* Min width 576px to Max width 767px */
@media only #{$media} and ($feature_min : $value_one) and ($feature_max : $value_two) {
    .spacle-nav {
        .navbar {
            .others-options {
                margin-left: 0;
                padding: 0 20px 12px;

                .default-btn {
                    display: inline-block;
                    width: auto;
                    margin-right: 10px;
                }
                .optional-btn {
                    display: inline-block;
                    width: auto;
                    margin: {
                        top: 0;
                    }
                }
            }
        }
    }

    .main-banner-content {
        .content {
            max-width: 540px;
            margin: {
                left: auto;
                right: auto;
            }
        }
    }
    .banner-content {
        .content {
            max-width: 540px;
            margin: {
                left: auto;
                right: auto;
            }
        }
    }
    .chat-wrapper {
        max-width: 540px;
        margin: {
            left: auto;
            right: auto;
        }
    }

    .partner-area {
        .container {
            max-width: 540px;
        }
    }

    .single-feedback-item {
        margin-top: 130px;

        img {
            display: inline-block !important;
        }
        .feedback-desc {
            padding: 25px 25px 25px 160px;
            margin-left: 45px;
        }
    }
    .feedback-slides {
        &.owl-theme {
            .owl-stage-outer {
                padding: {
                    top: 0;
                    left: 25px;
                    right: 25px;
                }
                margin: {
                    left: -25px;
                    right: -25px;
                    top: 0;
                }
            }
        }
    }

    .faq-area {
        .container-fluid {
            max-width: 540px;
        }
    }

    .single-clients-logo {
        -ms-flex: 0 0 33.3333333333%;
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .app-download-content {
        .btn-box {
            margin-top: 20px;

            .apple-store-btn {
                margin-right: 2px;
                display: inline-block;
                width: auto;
            }
            .play-store-btn {
                margin-left: 2px;
                display: inline-block;
                width: auto;
            }
        }
    }

    .login-content {
        .login-form {
            max-width: 410px;
            margin: {
                left: auto;
                right: auto;
            }
        }
    }

    .signup-content {
        .signup-form {
            max-width: 410px;
            margin: {
                left: auto;
                right: auto;
            }
        }
    }

    .prev-link-wrapper {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 10px;
    }
    .next-link-wrapper {
        margin-top: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: 10px;
    }

    .contact-inner {
        padding: 35px 30px;
    }

    /* Service details */
    .service-details-content {
        .service-details-info {
            .single-info-box {
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    /* End Service details */

    .digital-agency-banner {
        .container {
            max-width: 540px;
        }
    }

    .about-area {
        .container-fluid {
            max-width: 540px;
        }
    }

    .sm-services-card {
        .content {
            padding-left: 90px;

            .icon {
                position: absolute;
                margin-bottom: 0;
            }
        }
    }

    .sm-pricing-card {
        padding: 35px 30px;
    }
}

/* Min width 768px to Max width 991px */
@media only #{$media} and ($feature_min : $value_three) and ($feature_max : $value_four) {
    body {
        font-size: 14px;
    }
    p {
        font-size: 14px;
    }
    .ptb-100 {
        padding: {
            top: 80px;
            bottom: 80px;
        }
    }
    .pt-100 {
        padding-top: 80px;
    }
    .pb-100 {
        padding-bottom: 80px;
    }
    .pt-70 {
        padding-top: 50px;
    }
    .pb-70 {
        padding-bottom: 50px;
    }
    .section-title {
        margin-bottom: 45px;

        h2 {
            font-size: 30px;
        }
    }
    .default-btn {
        font-size: 14px;
    }

    .main-banner-content {
        height: auto;
        padding: {
            top: 80px;
        }
        .content {
            max-width: 620px;
            text-align: center;
            padding-right: 0;
            margin: {
                left: auto;
                top: 0;
                right: auto;
            }
            h1 {
                font-size: 40px;
                font-weight: 500;
            }
            p {
                margin-top: 15px;
                max-width: 100%;
            }
            .default-btn {
                margin-top: 10px;
            }
        }
    }
    .banner-content {
        padding: {
            top: 80px;
            bottom: 0;
        }
        .content {
            max-width: 620px;
            text-align: center;
            padding-right: 0;
            margin: {
                left: auto;
                right: auto;
            }
            h1 {
                font-size: 40px;
                font-weight: 500;
            }
            p {
                margin-top: 15px;
                max-width: 100%;
            }
            .default-btn {
                margin-top: 10px;
            }
        }
    }
    .banner-image {
        margin-top: 60px;
        height: auto;
        border-radius: 0;
        background-image: unset !important;
        padding-top: 50px;

        img {
            display: inline-block;
            width: 100%;
        }
    }
    .banner-img {
        margin-top: 60px;
        height: auto;
        border-radius: 0;
        background-image: unset !important;
        padding-top: 50px;

        img {
            display: inline-block;
            width: 100%;
        }
    }
    .banner-content-slides {
        &.owl-theme {
            .owl-nav {
                text-align: center;
                margin-top: 35px;

                [class*="owl-"] {
                    font-size: 22px;
                    width: 40px;
                    height: 40px;
                    line-height: 45px;
                }
            }
        }
    }
    .shape1,
    .shape2,
    .shape3,
    .shape4,
    .shape5,
    .shape6,
    .shape7,
    .shape8,
    .shape9,
    .shape10,
    .shape11,
    .shape12,
    .shape13,
    .shape20,
    .shape21,
    .shape22,
    .shape23,
    .shape24,
    .shape26,
    .shape14,
    .shape15,
    .shape16,
    .shape17,
    .shape18 {
        display: none;
    }
    .chat-wrapper {
        position: relative;
        left: 0;
        top: 0;
        transform: unset;
        width: auto;
        height: auto;
        max-width: 720px;
        padding: {
            left: 15px;
            right: 15px;
        }
        margin: {
            left: auto;
            right: auto;
            bottom: -35px;
        }
    }

    .partner-area {
        .container {
            max-width: 720px;
        }
    }
    .partner-title {
        text-align: center;
        margin-bottom: 45px;

        h3 {
            font-size: 20px;
        }
    }

    .services-area {
        &.bg-right-color {
            &::before {
                display: none;
            }
        }
        &.bg-left-color {
            &::before {
                display: none;
            }
        }
        .container-fluid {
            max-width: 720px;
        }
    }
    .services-content {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        order: 1;

        .content {
            max-width: 650px;
            padding-left: 0;
            text-align: center;
            margin: {
                left: auto;
                right: auto;
            }
            &.left-content {
                padding-right: 0;
                margin-left: auto;
            }
            h2 {
                font-size: 30px;
            }
            p {
                max-width: 100%;
                margin-top: 15px;
            }
            .default-btn {
                margin-top: 10px;
            }
        }
    }
    .services-image {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        order: 2;
        margin-top: 35px;
    }

    .about-content {
        text-align: center;

        h2 {
            font-size: 30px;
        }
    }
    .about-image {
        margin-top: 30px;
    }

    .video-box {
        max-width: 660px;
    }
    .funfacts-inner {
        max-width: 720px;
        margin-top: 80px;
    }
    .contact-cta-box {
        max-width: 720px;
        padding: 30px 200px 30px 30px;
        margin-top: 30px;

        h3 {
            font-size: 20px;
        }
        .default-btn {
            right: 30px;
        }
    }

    .single-features-box {
        h3 {
            font-size: 20px;
        }
    }
    .features-box {
        padding: 25px;

        h3 {
            font-size: 20px;
        }
        .icon {
            width: 80px;
            height: 80px;
            font-size: 40px;
        }
    }

    .single-feedback-item {
        margin-top: 125px;

        .feedback-desc {
            .client-info {
                h3 {
                    font-size: 17px;
                }
                span {
                    font-size: 13px;
                }
            }
        }
    }
    .single-testimonials-item {
        .client-info {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 15px;

            h3 {
                font-size: 17px;
            }
            span {
                font-size: 13px;
            }
        }
        .testimonials-desc {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            padding-left: 0;
            text-align: center;
        }
    }

    .single-pricing-table {
        .pricing-header {
            h3 {
                font-size: 20px;
            }
        }
        .price {
            font-size: 30px;

            sup {
                top: -13px;
                font-size: 16px;
            }
        }
    }

    .faq-area {
        .container-fluid {
            max-width: 720px;
        }
    }
    .faq-accordion {
        margin-left: 0;
        max-width: 100%;
        text-align: center;

        h2 {
            font-size: 30px;
        }
        .accordion {
            text-align: start;

            .accordion-title {
                font-size: 15px;
            }
        }
    }
    .faq-image {
        margin-top: 30px;
    }

    .single-clients-logo {
        a {
            padding: 20px 15px;
        }
    }

    .free-trial-content {
        max-width: 520px;

        h2 {
            font-size: 30px;
        }
    }

    .app-download-content {
        text-align: center;
        max-width: 615px;
        margin: {
            top: 30px;
            left: auto;
            right: auto;
        }
        h2 {
            font-size: 30px;
        }
        .btn-box {
            margin-top: 20px;

            .apple-store-btn {
                text-align: start;

                span {
                    font-size: 18px;
                }
            }
            .play-store-btn {
                text-align: start;

                span {
                    font-size: 18px;
                }
            }
        }
    }

    .subscribe-content {
        padding: 45px;

        h2 {
            max-width: 505px;
            font-size: 30px;
        }
        form {
            max-width: 540px;
        }
    }

    .single-blog-post {
        .post-content {
            h3 {
                font-size: 20px;
            }
            .post-info {
                .post-by {
                    -ms-flex: 0 0 60%;
                    flex: 0 0 60%;
                    max-width: 60%;
                }
                .details-btn {
                    -ms-flex: 0 0 40%;
                    flex: 0 0 40%;
                    max-width: 40%;
                }
            }
        }
    }

    .page-title-area {
        padding: {
            top: 80px;
            bottom: 70px;
        }
    }
    .page-title-content {
        h2 {
            font-size: 30px;
        }
        p {
            margin-top: 10px;
        }
    }

    .login-image {
        height: auto;
        background-image: unset !important;

        img {
            display: inline-block;
        }
    }
    .login-content {
        height: auto;
        padding: {
            top: 80px;
            bottom: 80px;
        }
        .login-form {
            .logo {
                margin-bottom: 30px;
            }
            h3 {
                font-size: 30px;
            }
        }
    }

    .signup-image {
        height: auto;
        background-image: unset !important;

        img {
            display: inline-block;
        }
    }
    .signup-content {
        height: auto;
        padding: {
            top: 80px;
            bottom: 80px;
        }
        .signup-form {
            .logo {
                margin-bottom: 30px;
            }
            h3 {
                font-size: 30px;
            }
        }
    }

    .widget-area {
        margin-top: 40px;

        .widget {
            .widget-title {
                font-size: 20px;
            }
        }
    }

    .blog-details-desc {
        .article-content {
            .entry-meta {
                ul {
                    li {
                        &::before {
                            top: 10px;
                        }
                    }
                }
            }
            h3 {
                font-size: 20px;
            }
        }
    }
    blockquote,
    .blockquote {
        p {
            font-size: 20px !important;
        }
    }
    .prev-link-wrapper {
        .prev-title {
            font-size: 16px;
        }
    }
    .next-link-wrapper {
        .next-title {
            font-size: 16px;
        }
    }
    .comments-area {
        .comments-title {
            font-size: 20px;
        }
        .comment-author {
            font-size: 15px;
        }
        .comment-respond {
            .comment-reply-title {
                font-size: 20px;
            }
            .form-submit {
                input {
                    padding: 10px 25px;
                }
            }
        }
    }

    .contact-features-list {
        h3 {
            font-size: 20px;
        }
    }
    .contact-form {
        margin-top: 30px;

        h3 {
            margin-bottom: 20px;
            font-size: 25px;
        }
    }
    .contact-info {
        .contact-info-content {
            h3 {
                font-size: 20px;
            }
            h2 {
                font-size: 30px;

                span {
                    font-size: 14px;
                }
            }
        }
    }

    .single-footer-widget {
        h3 {
            margin-bottom: 28px;
            font-size: 20px;
        }
    }
    .divider {
        height: 80px;
        top: -80px;
    }

    .main-banner-one {
        .banner-image {
            padding-top: 40px;
            padding-bottom: 40px;
            padding-left: 40px;
            padding-right: 40px;
            .mbanner-img {
                display: none;
            }
        }
    }
    .banner-image.mbanner-bg-one {
        .animate-banner-image {
            margin-top: 0;
        }
    }
    .main-banner-two {
        .banner-image-slider {
            .banner-image {
                padding-top: 0;
                height: 450px;
                img {
                    display: block;
                }
            }
        }
    }

    .single-pricing-table.center-align {
        .pricing-features {
            margin-left: 35px;
            margin-right: 35px;
        }
    }

    .banner-img.banner-video {
        height: auto;
        padding-top: 0;
        width: 20%;
    }
    .banner-content-slides {
        padding-right: 0;
    }

    /* SaaS home page */
    .saas-banner {
        padding-bottom: 60px;
        padding-top: 60px;
        text-align: center;
        .saas-image {
            &.mt-70 {
                margin-top: 0;
            }
            img {
                display: none;
                &:last-child {
                    display: block;
                    position: relative;
                    margin: auto;
                }
            }
        }
        .hero-content {
            h1 {
                font-size: 30px;
                line-height: 1.3;
            }
            p {
                font-size: 16px;
                margin-top: 15px;
                margin-bottom: 25px;
            }
            .banner-btn {
                max-width: 330px;
                margin: auto;
            }
            .video-btn {
                margin-left: 10px;
                font-size: 14px;
                i {
                    font-size: 17px;
                    height: 30px;
                    width: 30px;
                    line-height: 30px;
                    padding-left: 4px;
                    margin-right: 5px;
                }
            }
        }
        .hero-content.pl-4 {
            padding: 0 !important;
            margin-top: 30px;
        }
    }
    .shape-rotate {
        display: none;
    }
    .overview-left-img {
        margin-bottom: 30px;
    }
    .overview-right-img {
        margin-top: 30px;
    }
    .overview-item {
        margin-bottom: 30px;
        padding-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
    .overview-content.pl-3 {
        padding-left: 0 !important;
    }
    /* End SaaS home page */

    /* Service details */
    .service-details-content {
        .service-details-info {
            padding: 30px 20px 0;
            .single-info-box {
                -ms-flex: 0 0 33%;
                flex: 0 0 33%;
                max-width: 33%;
                margin-bottom: 30px;
            }
        }
    }
    /* End Service details */

    .section-title {
        &.text-start {
            text-align: center !important;
            margin: {
                left: auto;
                right: auto;
            }
        }
    }

    .digital-agency-banner {
        padding: {
            top: 150px;
            bottom: 150px;
        }
        .container {
            max-width: 720px;
        }
    }
    .digital-agency-banner-content {
        h1 {
            margin-bottom: 20px;
            font-size: 45px;
        }
        .default-btn {
            margin-top: 10px;
        }
    }

    .single-featured-box {
        h3 {
            font-size: 20px;
        }
    }

    .about-area {
        .section-title {
            &.text-start {
                margin-bottom: 45px;
            }
        }
        .container-fluid {
            max-width: 720px;
        }
    }
    .about-inner-area {
        padding: {
            top: 0;
            bottom: 0;
        }
    }
    .about-inner-image {
        height: auto;
        background-image: unset !important;

        img {
            display: inline-block;
        }
    }
    .about-inner-content {
        padding: 40px;
        margin: {
            top: 0;
            bottom: 0;
        }
        .content {
            max-width: 540px;
            margin: {
                left: auto;
                right: auto;
            }
            h2 {
                font-size: 26px;
            }
            .features-list {
                margin-top: 20px;

                li {
                    font-size: 15.5px;

                    i {
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        font-size: 23px;
                        top: 2.5px;
                    }
                }
            }
        }
    }

    .single-services-box {
        .content {
            padding: 25px 20px;

            h3 {
                font-size: 20px;
                margin-right: 0;
            }
        }
        .row {
            .col-lg-6 {
                &:nth-child(1) {
                    order: 2;
                }
                &:nth-child(2) {
                    order: 1;
                }
            }
        }
        .image {
            height: auto;
            -webkit-clip-path: unset !important;
            clip-path: unset !important;
            background-image: unset !important;

            img {
                display: inline-block;
            }
        }
    }

    .case-studies-area {
        .container-fluid {
            max-width: 720px;
            padding: {
                left: 15px;
                right: 15px;
            }
        }
    }
    .case-studies-slides {
        &.owl-theme {
            left: 0;

            .owl-nav {
                position: relative;
                right: 0;
                top: 0;
                margin: {
                    bottom: 30px;
                    top: 10px;
                }
                [class*="owl-"] {
                    margin: {
                        left: 5px !important;
                        right: 5px !important;
                    }
                }
            }
        }
    }
    .single-case-studies-item {
        .content {
            padding: 30px 20px 25px;

            h3 {
                font-size: 20px;
            }
        }
    }

    .feedback-area {
        .section-title {
            &.text-start {
                margin-bottom: 45px;
            }
        }
    }
    .testimonials-slides-two {
        max-width: 540px;
        margin: {
            left: auto;
            right: auto;
        }
    }

    .team-area-two {
        .section-title {
            margin-bottom: 45px;
        }
    }
    .single-team-member {
        .content {
            h3 {
                font-size: 20px;
            }
        }
    }
    .col-lg-3 {
        &:nth-child(1) {
            .single-team-member {
                margin-top: 0;
            }
        }
        &:nth-child(2) {
            .single-team-member {
                margin-top: 0;
            }
        }
        &:nth-child(3) {
            .single-team-member {
                margin-top: 0;
            }
        }
    }

    .single-blog-post-item {
        .post-content {
            h3 {
                font-size: 20px;
            }
        }
    }
    .blog-slides {
        &.owl-theme {
            .owl-nav {
                position: relative;
                right: 0;
                top: 0;
                margin: {
                    bottom: 30px;
                    top: 10px;
                }
                [class*="owl-"] {
                    margin: {
                        left: 5px !important;
                        right: 5px !important;
                    }
                }
            }
        }
    }

    .company-preview-area {
        padding: {
            top: 150px;
            bottom: 150px;
        }
    }
    .company-preview-video {
        .video-btn {
            width: 90px;
            height: 90px;
            font-size: 80px;
        }
    }

    .lets-talk-content {
        text-align: center;

        h2 {
            font-size: 30px;
        }
    }
    .lets-talk-btn {
        text-align: center;
        margin-top: 20px;
    }

    .seo-marketing-banner {
        .container-fluid {
            padding-right: calc(var(--bs-gutter-x) * 0.5);
            padding-left: calc(var(--bs-gutter-x) * 0.5);
            max-width: 720px;
        }
    }
    .seo-marketing-banner-content {
        h1 {
            font-size: 55px;
        }
    }
    .seo-marketing-banner-shape-1,
    .seo-marketing-banner-shape-2,
    .seo-marketing-banner-shape-3 {
        display: none;
    }
    .seo-marketing-banner-image {
        text-align: center;
        margin-top: 35px;
    }

    .sm-features-card {
        h3 {
            font-size: 20px;
        }
    }

    .sm-about-content {
        padding-left: 0;
        margin-top: 35px;
    }
    .sm-about-shape-1,
    .sm-about-shape-2 {
        display: none;
    }

    .sm-websites-inner-area {
        padding: {
            left: 35px;
            right: 35px;
        }
    }
    .sm-websites-content {
        padding-right: 0;

        h3 {
            font-size: 30px;
        }
    }
    .sm-websites-image {
        text-align: center;
        margin-top: 30px;
    }

    .sm-services-card {
        &.some-left {
            left: 0;
        }
        &.some-right {
            right: 0;
        }
        .content {
            h3 {
                font-size: 20px;
            }
        }
    }
    .sm-services-card-image {
        padding: {
            left: 0;
            right: 0;
        }
        text-align: center;
    }
    .sm-services-shape {
        display: none;
    }

    .sm-funfacts-inner-box {
        padding: {
            left: 20px;
            right: 20px;
        }
    }
    .sm-single-funfacts {
        padding-left: 0;
        text-align: center;

        img {
            position: relative;
            top: unset;
            transform: unset;
            margin-bottom: 20px;
            display: inline-flex;
            height: 70px;
            justify-content: center;
        }
        h3 {
            font-size: 35px;

            .sign-icon {
                font-size: 30px;
            }
        }
        p {
            font-size: 14.5px;
        }
    }

    .sm-work-content {
        h3 {
            font-size: 30px;
        }
        .sm-work-inner-card {
            h4 {
                font-size: 20px;
                margin-bottom: 12px;
            }
        }
    }
    .sm-work-image {
        text-align: center;
        margin-top: 35px;
    }

    .sm-faq-image {
        text-align: center;
    }
    .sm-faq-accordion {
        padding-left: 0;
        margin-top: 35px;

        h2 {
            margin-bottom: 25px;
            font-size: 30px;
        }
    }

    .sm-partner-inner-box {
        padding: {
            left: 20px;
            right: 20px;
        }
        .section-title-with-large-box {
            span {
                font-size: 20px;
            }
        }
    }
    .sm-partner-shape {
        display: none;
    }

    .sm-overview-card {
        .image-icon {
            height: 100px;
            width: 100px;
            line-height: 100px;

            img {
                max-width: 50px;
            }
        }
        h3 {
            font-size: 18px;
        }
    }

    .sm-pricing-card {
        padding: 20px;

        .pricing-header {
            margin-bottom: 20px;

            h3 {
                font-size: 18px;
            }
        }
        .price {
            font-size: 35px;

            span {
                font-size: 12px;
                top: -5px;
            }
        }
        .pricing-features {
            margin-top: 22px;

            li {
                font-size: 14.5px;
                padding-left: 22px;
            }
        }
    }

    .sm-subscribe-inner-box {
        padding: {
            left: 20px;
            right: 20px;
        }
    }
    .sm-subscribe-shape-1,
    .sm-subscribe-shape-2 {
        display: none;
    }

    .sm-blog-card {
        .blog-content {
            h3 {
                font-size: 20px;
            }
        }
    }
    .sm-blog-shape {
        display: none;
    }

    .footer-area-with-black-color {
        .single-footer-widget {
            &.ps-5 {
                padding-left: 0 !important;
            }
        }
    }
    .footer-shape-1,
    .footer-shape-2 {
        display: none;
    }

    .top-bar-area {
        .container-fluid {
            padding-right: calc(var(--bs-gutter-x) * 0.5);
            padding-left: calc(var(--bs-gutter-x) * 0.5);
            max-width: 720px;
        }
    }
    .top-bar-info {
        text-align: center;

        li {
            margin-bottom: 10px;
        }
    }
    .top-bar-social {
        justify-content: center;

        li {
            a {
                i {
                    font-size: 15px;
                }
            }
        }
    }

    .is-banner-item {
        padding: {
            top: 100px;
            bottom: 200px;
        }
        margin: {
            left: 0;
            right: 0;
        }
        border-radius: 0;
    }
    .is-banner-content {
        h1 {
            font-size: 55px;
        }
    }
    .is-banner-slides {
        &.owl-theme {
            .owl-nav {
                right: 0;
                left: 0;
                top: unset;
                transform: unset;
                bottom: 55px;

                [class*="owl-"] {
                    margin: 0 5px;
                    font-size: 18px;
                    display: inline-block;
                }
            }
        }
    }

    .is-features-card {
        padding: 80px 25px 25px 25px;

        h3 {
            font-size: 20px;
        }
        .icon {
            i {
                height: 65px;
                width: 65px;
                line-height: 65px;
                font-size: 30px;
            }
        }
    }

    .is-about-content {
        padding-right: 0;

        h3 {
            font-size: 30px;
        }
    }
    .is-about-image {
        text-align: center;
        margin-top: 35px;

        .is-about-shape {
            display: none;
        }
    }

    .is-services-area {
        .container-fluid {
            padding-right: calc(var(--bs-gutter-x) * 0.5);
            padding-left: calc(var(--bs-gutter-x) * 0.5);
            max-width: 720px;
        }
    }
    .is-services-card {
        padding: 20px;

        h3 {
            font-size: 18px;
        }
    }

    .is-expertise-content {
        padding-left: 0;
        margin-top: 30px;

        h3 {
            font-size: 25px;
        }
    }

    .is-case-studies-area {
        .section-title-with-large-box {
            .default-btn {
                display: none;
            }
        }
    }
    .is-case-studies-list-tabs {
        .nav {
            .nav-item {
                .nav-link {
                    font-size: 18px;
                }
            }
        }
    }
    .is-case-studies-image {
        padding-left: 0;
        margin-top: 30px;

        .content {
            padding: 20px;
            border-radius: 10px;
            position: relative;
            right: 0;
            bottom: 0;
            max-width: 100%;
            margin-top: 10px;
        }
    }

    .is-testimonials-card {
        p {
            padding: 25px;
        }
    }

    .is-team-content {
        padding-right: 0;
        margin-bottom: 35px;

        h3 {
            font-size: 30px;
        }
    }
    .is-team-member {
        .content {
            h3 {
                font-size: 18px;
            }
        }
    }

    .is-analysis-content {
        padding-left: 0;
        margin-top: 35px;

        h3 {
            font-size: 30px;
        }
    }

    .me-banner-area {
        .container-fluid {
            padding-right: calc(var(--bs-gutter-x) * 0.5);
            padding-left: calc(var(--bs-gutter-x) * 0.5);
            max-width: 720px;
        }
    }
    .me-banner-content {
        h1 {
            font-size: 55px;
        }
    }
    .me-banner-image {
        margin-top: 30px;
        text-align: center;
    }
    .me-banner-shape {
        display: none;
    }

    .me-features-card {
        padding-right: 0;

        h3 {
            font-size: 20px;
        }
    }

    .me-about-content {
        padding-left: 0;
        margin-top: 30px;

        h3 {
            font-size: 30px;
        }
    }

    .sm-funfacts-inner-box {
        &.bg-with-linear-gradient {
            .sm-single-funfacts {
                i {
                    height: 60px;
                    width: 60px;
                    line-height: 60px;
                    font-size: 30px;
                    position: relative;
                    margin-bottom: 15px;
                }
                p {
                    font-size: 14.5px;
                }
            }
        }
    }

    .me-services-shape1,
    .me-services-shape2,
    .me-services-shape3 {
        display: none;
    }

    .me-choose-content {
        h3 {
            font-size: 30px;
        }
        .choose-inner-card {
            h4 {
                font-size: 20px;
            }
        }
    }
    .me-choose-image {
        text-align: center;
        margin-top: 35px;
    }

    .me-team {
        .row {
            .col-lg-3 {
                &:nth-child(2) {
                    .is-team-member {
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
    .me-team-shape {
        display: none;
    }

    .sm-pricing-section-content {
        margin-bottom: 30px;

        h3 {
            font-size: 30px;
        }
    }
    .me-pricing-shape1,
    .me-pricing-shape2 {
        display: none;
    }

    .me-testimonials-shape1,
    .me-testimonials-shape2 {
        display: none;
    }
}

/* Min width 992px to Max width 1199px */
@media only #{$media} and ($feature_min : $value_five) and ($feature_max : $value_six) {
    .section-title {
        h2 {
            font-size: 34px;
        }
    }

    .navbar-area {
        position: relative;
        background-color: #ffffff;
    }
    .spacle-nav {
        .navbar {
            .navbar-nav {
                margin-left: auto;
            }
            .others-options {
                margin-left: 25px;

                .optional-btn {
                    display: none;
                }
            }
        }
    }

    .main-banner-content {
        .content {
            max-width: 550px;
            padding-right: 140px;
            margin-top: 0;

            h1 {
                font-size: 48px;
            }
        }
    }
    .banner-content {
        padding: {
            top: 145px;
            bottom: 130px;
        }
        .content {
            max-width: 550px;
            padding-right: 140px;

            h1 {
                font-size: 48px;
            }
        }
    }
    .banner-image {
        height: 770px;
    }
    .chat-wrapper {
        width: 460px;
    }

    .partner-area {
        .container {
            max-width: 960px;
        }
    }

    .services-content {
        .content {
            max-width: 485px;
            padding-left: 15px;

            .icon {
                margin-bottom: 18px;
            }
            h2 {
                font-size: 34px;
            }
            .default-btn {
                margin-top: 5px;
            }
            &.left-content {
                padding-right: 15px;
            }
        }
    }

    .features-box {
        padding: 30px 19px;

        h3 {
            font-size: 20px;
        }
    }

    .faq-accordion {
        max-width: 570px;
    }

    .single-blog-post {
        .post-content {
            .post-info {
                .post-by {
                    -ms-flex: 0 0 65%;
                    flex: 0 0 65%;
                    max-width: 65%;
                }
                .details-btn {
                    -ms-flex: 0 0 35%;
                    flex: 0 0 35%;
                    max-width: 35%;
                }
            }
        }
    }

    .shape10,
    .shape11,
    .shape12,
    .shape13,
    .shape21,
    .shape22 {
        display: none;
    }

    .single-feedback-item {
        margin-top: 40px;
    }

    .app-download-content {
        h2 {
            font-size: 34px;
        }
    }

    .free-trial-content {
        h2 {
            font-size: 34px;
        }
    }

    .about-content {
        h2 {
            font-size: 34px;
        }
    }

    .faq-accordion {
        h2 {
            font-size: 34px;
        }
    }

    .contact-form {
        h3 {
            font-size: 30px;
        }
    }
    .contact-info {
        .contact-info-content {
            h2 {
                font-size: 34px;
            }
        }
    }

    .login-content {
        .login-form {
            max-width: 450px;
        }
    }

    .signup-content {
        .signup-form {
            max-width: 450px;
        }
    }

    .single-features-card {
        padding: 20px;

        h3 {
            font-size: 18px;
        }
    }
    .features-box-one {
        h3 {
            font-size: 20px;
        }
    }
    .single-blog-post {
        .post-content {
            h3 {
                font-size: 18px;
            }
        }
    }
    .banner-image.mbanner-bg-one {
        .animate-banner-image {
            margin-top: 0;
        }
    }
    .single-pricing-table.center-align {
        .pricing-features {
            margin-left: 30px;
            margin-right: 30px;
        }
    }
    .services-area.bg-right-shape {
        &::before {
            width: 67%;
        }
    }
    .services-area.bg-left-shape {
        &::before {
            width: 63%;
        }
    }
    .chatbot-main-banner {
        .main-banner-content {
            .content {
                padding-right: 115px;
            }
        }
    }
    /* SaaS Home */
    .saas-banner {
        .row.align-items-center.pt-5 {
            padding: 0 !important;
        }
        .hero-content {
            h1 {
                font-size: 40px;
                line-height: 1.3;
            }
            p {
                font-size: 17px;
                margin-top: 20px;
                margin-bottom: 25px;
            }
        }
    }
    /* End SaaS Home */

    /* Service details */
    .service-details-content {
        .service-details-info {
            .single-info-box {
                .social li {
                    display: inline-block;
                    margin-right: 2px;
                }
            }
        }
    }
    /* End Service details */

    .navbar-area {
        &.p-relative {
            .spacle-nav {
                .container {
                    max-width: 960px;
                }
            }
        }
    }

    .digital-agency-banner {
        padding: {
            top: 200px;
            bottom: 200px;
        }
        .container {
            max-width: 960px;
        }
    }
    .digital-agency-banner-content {
        h1 {
            font-size: 50px;
        }
    }

    .about-inner-content {
        padding: 40px;

        .content {
            max-width: 100%;

            h2 {
                font-size: 30px;
            }
            .features-list {
                li {
                    font-size: 16px;

                    i {
                        width: 30px;
                        height: 30px;
                        line-height: 31px;
                        font-size: 25px;
                        top: 4px;
                    }
                }
            }
        }
    }

    .single-featured-box {
        h3 {
            font-size: 21px;
        }
    }

    .single-services-box {
        .content {
            h3 {
                font-size: 20px;
            }
        }
    }

    .case-studies-area {
        .container-fluid {
            max-width: 960px;
            padding: {
                left: 15px;
                right: 15px;
            }
        }
    }
    .case-studies-slides {
        &.owl-theme {
            left: 0;

            .owl-nav {
                right: 0;
            }
        }
    }
    .single-case-studies-item {
        .content {
            padding: 30px 15px 20px;

            h3 {
                font-size: 20px;
            }
        }
    }

    .single-team-member {
        .content {
            padding: 30px 18px 15px;

            h3 {
                font-size: 20px;
            }
        }
    }

    .single-blog-post-item {
        .post-content {
            h3 {
                font-size: 20px;
            }
        }
    }

    .company-preview-area {
        padding: {
            top: 200px;
            bottom: 200px;
        }
    }

    .lets-talk-content {
        h2 {
            font-size: 34px;
        }
    }

    .seo-marketing-banner-content {
        h1 {
            font-size: 50px;
        }
    }
    .seo-marketing-banner-shape-2 {
        bottom: 12%;
        left: 40%;
        transform: translateY(-12%) translateX(-40%);
    }

    .sm-features-card {
        padding: 20px;

        h3 {
            font-size: 18px;
        }
    }

    .sm-about-content {
        padding-left: 0;

        .list {
            li {
                font-size: 15px;
            }
        }
    }

    .sm-websites-inner-area {
        padding: {
            left: 50px;
            right: 50px;
        }
    }
    .sm-websites-content {
        padding-right: 0;

        h3 {
            font-size: 30px;
        }
    }

    .sm-services-card {
        padding: 25px 18px;

        .content {
            padding-left: 55px;

            .icon {
                i {
                    height: 45px;
                    width: 45px;
                    line-height: 45px;
                    font-size: 20px;
                }
            }
            h3 {
                font-size: 18px;
                margin-bottom: 12px;
            }
        }
        &.some-left {
            left: 0;
        }
        &.some-right {
            right: 0;
        }
    }
    .sm-services-card-image {
        padding: {
            left: 0;
            right: 0;
        }
    }

    .sm-funfacts-inner-box {
        padding: {
            left: 30px;
            right: 30px;
        }
    }
    .sm-single-funfacts {
        padding-left: 55px;

        img {
            max-width: 40px;
        }
        h3 {
            font-size: 35px;

            .sign-icon {
                font-size: 28px;
            }
        }
        p {
            font-size: 14.5px;
        }
    }

    .sm-pricing-card {
        padding: 20px;

        .pricing-header {
            margin-bottom: 20px;

            h3 {
                font-size: 18px;
            }
        }
        .price {
            font-size: 35px;

            span {
                font-size: 14px;
            }
        }
        .pricing-features {
            li {
                color: #4a6f8a;
                font-size: 12.5px;
                padding-left: 22px;
            }
        }
    }

    .sm-blog-card {
        .blog-content {
            h3 {
                font-size: 20px;
            }
        }
    }

    .top-bar-info {
        li {
            margin-right: 12px;
            font-size: 14px;
        }
    }
    .top-bar-social {
        li {
            margin-right: 5px;
            font-size: 14px;

            a {
                i {
                    font-size: 15px;
                    position: relative;
                    top: 1px;
                }
            }
        }
    }

    .is-banner-content {
        h1 {
            font-size: 65px;
        }
    }

    .is-features-card {
        padding: 80px 25px 25px 25px;
    }

    .is-services-card {
        padding: 25px;

        h3 {
            font-size: 18px;
        }
    }

    .is-expertise-content {
        padding-left: 8px;

        h3 {
            line-height: 1.3;
        }
    }

    .is-case-studies-list-tabs {
        .nav {
            .nav-item {
                .nav-link {
                    font-size: 15px;
                }
            }
        }
    }
    .is-case-studies-image {
        padding-left: 0;

        .content {
            bottom: -80px;
            max-width: 475px;
        }
    }

    .is-team-member {
        .content {
            padding: 20px 62px 20px 10px;

            h3 {
                font-size: 15px;
            }
            i {
                right: 10px;
            }
        }
    }

    .is-analysis-content {
        padding-left: 10px;
    }

    .me-banner-content {
        h1 {
            font-size: 48px;
        }
    }

    .me-features-card {
        h3 {
            font-size: 20px;
        }
    }

    .me-about-content {
        padding-left: 15px;

        .list {
            li {
                font-size: 15px;
            }
        }
    }

    .sm-funfacts-inner-box {
        &.bg-with-linear-gradient {
            .sm-single-funfacts {
                padding-left: 0;
                text-align: center;

                i {
                    position: relative;
                    margin-bottom: 15px;
                }
            }
        }
    }
}

/* Min width 1200px to Max width 1300px */
@media only #{$media} and ($feature_min : $value_seven) and ($feature_max : $value_eight) {
    .spacle-nav {
        .navbar .navbar-nav {
            margin-left: 70px;
        }
    }

    .main-banner-content {
        .content {
            max-width: 675px;
            padding-right: 150px;

            h1 {
                font-size: 50px;
            }
        }
    }
    .banner-content {
        .content {
            max-width: 675px;
            padding-right: 150px;

            h1 {
                font-size: 50px;
            }
        }
    }
    .shape21 {
        display: none;
    }

    .partner-area {
        .container {
            max-width: 1140px;
        }
    }

    .case-studies-slides {
        &.owl-theme {
            left: calc((100% - 1115px) / 2);
        }
    }

    .sm-features-card {
        h3 {
            font-size: 22px;
        }
    }

    .sm-about-content {
        .list {
            li {
                font-size: 15px;
            }
        }
    }

    .sm-services-card {
        .content {
            padding-left: 82px;

            .icon {
                i {
                    height: 65px;
                    width: 65px;
                    line-height: 65px;
                    font-size: 30px;
                }
            }
            h3 {
                font-size: 18px;
                margin-bottom: 12px;
            }
        }
    }

    .sm-single-funfacts {
        padding-left: 85px;

        img {
            max-width: 65px;
        }
        h3 {
            font-size: 35px;

            .sign-icon {
                font-size: 35px;
            }
        }
        p {
            font-size: 14.5px;
        }
    }

    .is-case-studies-image {
        padding-left: 15px;
    }

    .is-team-member {
        .content {
            h3 {
                font-size: 16px;
            }
        }
    }

    .me-banner-content {
        h1 {
            font-size: 60px;
        }
    }

    .sm-funfacts-inner-box {
        &.bg-with-linear-gradient {
            .sm-single-funfacts {
                padding-left: 90px;
            }
        }
    }

    .sm-pricing-card {
        padding: 30px 20px;
    }
}

/* Min width 1550px */
@media only #{$media} and ($feature_min : $value_nine) {
    .main-banner-content {
        .content {
            max-width: 785px;
        }
    }
    .banner-content {
        padding: {
            // top: 290px;
            bottom: 200px;
        }
        .content {
            max-width: 785px;
        }
    }

    .case-studies-slides {
        &.owl-theme {
            .owl-nav {
                right: 41.7%;
            }
        }
    }

    .top-bar-area {
        .container-fluid {
            max-width: 1920px;
            padding: {
                left: 100px;
                right: 100px;
            }
        }
    }

    .navbar-area-with-position-relative {
        .container-fluid {
            max-width: 1920px;
            padding: {
                left: 100px;
                right: 100px;
            }
        }
    }

    .seo-marketing-banner {
        .container-fluid {
            max-width: 1920px;
            padding: {
                left: 100px;
                right: 100px;
            }
        }
    }

    .me-banner-area {
        .container-fluid {
            max-width: 1920px;
            padding: {
                left: 100px;
                right: 100px;
            }
        }
    }
}

/* Min width 1800px */
@media only #{$media} and ($feature_min : $value_ten) {
    .services-area {
        &.bg-left-shape {
            &::before {
                width: 42%;
            }
        }
        &.bg-right-shape {
            &::before {
                width: 42%;
            }
        }
    }
}

.about-area {
    .default-btn {
        span {
            background-color: #000000;
        }
    }
}
